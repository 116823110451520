import React, {useState, useEffect} from "react";
import {styled, useTheme} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {Outlet, useOutletContext} from "react-router-dom";
import {Box, useMediaQuery} from "@mui/material";
import {AuthenticatedTemplate, useIsAuthenticated} from "@azure/msal-react";
import useHideOnRoute from "../../hooks/useHideOnRoute";
import CustomAppBar from "./CustomAppBar";
import CustomDrawer from "./CustomDrawer";
import CustomMobileAppBar from "./CustomMobileAppBar";
import CustomMobileDrawer from "./CustomMobileDrawer";
import CurrentCustomerContextProvider from "../../contexts/CurrentCustomerContext";
import CurrentUserContextProvider from "../../contexts/CurrentUserContext";

const DrawerHeader = styled("div")(({theme, hidden}) => ({
    display: hidden ? "none" : "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

type ContextType = { searchQuery: string, filterPreRegistered: boolean };

export function useAppBarProps() {
    return useOutletContext<ContextType>();
}

const HeaderContainer = () => {
    const hideOnRoute = useHideOnRoute("/realtimeview");
    const theme = useTheme();
    const isAuthenticated = useIsAuthenticated();
    const isBigScreen = useMediaQuery("(min-width: 1140px)");
    const [open, setOpen] = useState(isBigScreen && isAuthenticated);
    const [searchQuery, setSearchQuery] = React.useState<string>("");
    const [filterPreRegistered, setFilterPreRegistered] = React.useState<boolean>(false);

    useEffect(() => {
        if (isBigScreen !== null && isAuthenticated !== null && !hideOnRoute) {
            setOpen(isBigScreen && isAuthenticated);
        }
    }, [isBigScreen, isAuthenticated]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleSearchQuery = (query: string) => {
        setSearchQuery(query);
    };

    const handleFilterPreRegistered = (value: boolean) => {
        setFilterPreRegistered(value);
    };

    return (
        <>
        <CurrentUserContextProvider>
        <CurrentCustomerContextProvider>
        <Box sx={{display: "flex"}}>
            <CssBaseline/>
            <CustomAppBar
                open={open}
                hidden={hideOnRoute}
                handleDrawerOpen={handleDrawerOpen}
            />
            <CustomMobileAppBar
                open={open}
                hidden={!hideOnRoute}
                handleDrawerOpen={handleDrawerOpen}
                searchQuery={searchQuery}
                handleSearchQuery={handleSearchQuery}
                filterPreRegistered={filterPreRegistered}
                handleFilterPreRegistered={handleFilterPreRegistered}
            />

            <AuthenticatedTemplate>
                {!hideOnRoute ?
                    <CustomDrawer
                        open={open}
                        hidden={hideOnRoute}
                        handleDrawerClose={handleDrawerClose}
                    />
                    :
                    <CustomMobileDrawer
                        open={open}
                        hidden={!hideOnRoute}
                        handleDrawerClose={handleDrawerClose}
                    />}
            </AuthenticatedTemplate>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    paddingLeft: theme.spacing(3),
                    paddingRight: theme.spacing(3),
                    background: theme.palette.background.default,
                    minHeight: "100vh",
                }}>
                <DrawerHeader/>
                <Outlet context={{searchQuery, filterPreRegistered} satisfies ContextType}/>
            </Box>
        </Box>
        </CurrentCustomerContextProvider>
        </CurrentUserContextProvider>
        </>
    );
};

export default HeaderContainer;
