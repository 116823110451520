import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { fetchCustomer } from "../../api/CustomerAPI";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import ChangeCustomerModal from "../Facilities/Modals/ChangeCustomerModal";
import AddFacilityModal from "../Facilities/Modals/AddFacilityModal";
import {useEffect, useState} from "react";
import Facilities from "../Facilities/Facilities";
import { useCurrentCustomer } from "../../contexts/CurrentCustomerContext";
import { useCurrentUser } from "../../contexts/CurrentUserContext";
import { t } from "i18next";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import ReceiptIcon  from "@mui/icons-material/Receipt";
import CommuteIcon from "@mui/icons-material/Commute";
import {CircularProgress, Button, useTheme, Grid, Typography} from "@mui/material";

export default function Customer() {
  let { id } = useParams();
  const [customerData, setCustomerData] = useState({});
  const [customerName, setCustomerName] =  useState("");
  const [addFacility, setAddFacility] =  useState(false);
  const [loading, setLoading] =  useState(true);
  const {currentCustomer, setCurrentCustomer} = useCurrentCustomer();
  const {currentUser} = useCurrentUser();
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    getCustomer();
    setAddFacility(false);
  }, [id, addFacility]);

  const getCustomer = async () => {
    setLoading(true);
    if (id && currentUser && (currentUser.isAdmin || currentUser.customerIds?.length > 1)) {
        const {data} = await fetchCustomer(id);
        setCustomerData(data);
        setCustomerName(data.name);
        setCurrentCustomer({
          id: id,
          name: data.name,
          facilities: data.facilityNames,
          services: data.services,
          productNames: data.productNames,
          customerGroups: data.customerGroups
        });
    }
    else if(currentUser && currentUser.isAuthorized && !currentUser.isAdmin && currentUser.customerIds?.length === 1){
      const {data} = await fetchCustomer(currentUser.customerIds[0].toString());
      setCustomerData(data);
      setCustomerName(data.name);
      setCurrentCustomer({
        id: currentUser.customerIds[0],
        name: data.name,
        facilities: data.facilityNames,
        services: data.services,
        productNames: data.productNames,
        customerGroups: data.customerGroups
      });
    }
    setLoading(false);
  }

  function loadCustomer(): void {
    if (id) {
      getCustomer();
    }
  }

  function loadApiFacilities(): void {
    if (id) {
      setAddFacility(true);
    }
  }

  return (
      <>
      {currentCustomer && currentUser && currentCustomer.id > 0 && currentUser.isAuthorized &&  
      <>
        <Box display="flex" sx={{alignItems: "center" }}>
        {!currentUser.isAdmin ? (
          <>
          <Box display="grid" sx={{ marginBottom: theme.spacing(2), maxWidth:{xs: '100%', sm: '95%'}}}>
            <Grid container spacing={2} direction="row">
              <Grid item xs={12}>
                <Box sx={{ marginLeft: "1rem" }}>
                  <Typography variant="h6">{t("Välkommen_Kund", {currentUser})}</Typography>
                </Box>
              </Grid>
              <Grid item>
                <Button variant="outlined" size="large" color="inherit" endIcon={<EmojiTransportationIcon/>} onClick={() => navigate("/realtimeview")}>
                  {t("Gå_till")} {t("Realtids Vy")}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" size="large" color="inherit" endIcon={<ReceiptIcon/>} onClick={() => navigate("/billing")}>
                  {t("Gå_till")} {t("Fakturering")}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" size="large" color="inherit" endIcon={<CommuteIcon/>} onClick={() => navigate(`/arrivalhistory/${currentCustomer.id}`)}>
                  {t("Gå_till")} {t("Besökshistorik")}
                </Button>
              </Grid>
            </Grid>
          </Box>
          </>
          ):(
            <>
              {loading ? (
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                  </Box>
              ):(
                  <>
                    <WarehouseIcon/>
                    <Box sx={{ marginLeft: "1rem" }}>
                      {customerName && <h2>{customerName}</h2>}
                    </Box>
                  </>
              )}
            </>
          )}
          {currentCustomer && currentUser && currentCustomer.id > 0 && currentUser.isAdmin && !loading &&
          <>
          <Box  sx={{ marginLeft: "1rem" }}>
            <ChangeCustomerModal onSuccess={() => loadCustomer()} customerDetails={customerData}></ChangeCustomerModal>
          </Box>
          <Box  sx={{ marginLeft: "1rem" }}>
            <AddFacilityModal customer={customerData} onSuccess={() => loadApiFacilities()} ></AddFacilityModal>
          </Box>
          </>
          }
        </Box>
        {currentCustomer && currentUser && currentCustomer.id > 0 && currentUser.isAdmin &&  !loading &&
        <Facilities update={addFacility}/>
        }
        </>
      }
      </>
  );
}
