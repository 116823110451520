import {AuthenticatedPage} from "./AuthenticatedPage";
import React, {useEffect,  useState} from "react";
import { useCurrentCustomer } from "../contexts/CurrentCustomerContext";
import { FacilitySelectComponent } from "../components/Settings/FacilitySelectComponent";
import { UserFacility } from "../types/CurrentUserFacilities";
import { useCurrentUser } from "../contexts/CurrentUserContext";
import { FacilitySelection } from "../types/MobileNotifications";
import { PostMessageHelper } from "../helpers/PostMessageHelper";

export function FacilitySelectionView() {
    const [initialData, setData] = useState<UserFacility|null>(null);
    const {currentCustomer} = useCurrentCustomer();
    const {currentUser} = useCurrentUser();


    useEffect(() => {
        loadCurrentFacility();
    }, []);

    const loadCurrentFacility = () => {
        let data = window.localStorage.getItem("CURRENT_FACILITY");
        if(data){
            let obj:UserFacility;
            try {
                obj =  JSON.parse(data)
            }
            catch {
                obj = currentCustomer.facilities[0];
            }
            setData(obj);
        }
        else {
            setData(currentCustomer.facilities[0]);
        }
    }

    const getUserFacilityById = (id:number):UserFacility => {
        if(currentUser && currentUser.facilities && currentUser.facilities.length > 0){
            const userFacility = currentUser.facilities.find(f => f.id === id);
            return userFacility ? userFacility : currentUser.facilities[0];
        }
        else {
            const customerFacility = currentCustomer.facilities.find(f => f.id === id);
            return customerFacility ? customerFacility : currentCustomer.facilities[0];
        }

    }

    const handleOnSave = (changeData: number) => {
        const changedFacility = getUserFacilityById(changeData);
        setData(changedFacility);
        saveLocalStorage(changedFacility);
        const settingsData = new FacilitySelection("", "", changeData)
        PostMessageHelper(JSON.stringify(settingsData));
    }

    const saveLocalStorage = (changeData:UserFacility) => {
        let serializedData = JSON.stringify(changeData);
        window.localStorage.setItem("CURRENT_FACILITY", serializedData);
    }


    return (
        <AuthenticatedPage>
            {initialData && (
            <FacilitySelectComponent onSave={handleOnSave} data={initialData}/>
            )}
        </AuthenticatedPage>
    )
}