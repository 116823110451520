import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import { useCurrentCustomer } from "../../contexts/CurrentCustomerContext";
import { useCurrentUser } from "../../contexts/CurrentUserContext";
import { UserFacility } from "../../types/CurrentUserFacilities";


const mobilePhoneStyle = {
    padding: '10px',
    backgroundColor: '#f0f0f0',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    margin: 'auto',
    marginTop: '30px',
    overflow: "auto",
    '&::-webkit-scrollbar': {display: 'none'},
    border: '2px solid #000',
    borderRadius: 3,
    width:'95%',
    height:'90%'
};


export function FacilitySelectComponent({onSave, data}) {
    const [dataChange, setDataChange] = useState(false);
    const { t } = useTranslation();
    const {currentCustomer} = useCurrentCustomer();
    const {currentUser} = useCurrentUser();

    useEffect(() => {
        if(dataChange){
            //call api to change settings
            setDataChange(false);
        }
    }, [dataChange]);


    const selectableFacilities = ():UserFacility[] => {
        if(currentUser && currentUser.facilities && currentUser.facilities.length > 0){
            return currentUser.facilities;
        }
        else if (currentCustomer && currentCustomer.facilities && currentCustomer.facilities.length >0) {
            return currentCustomer.facilities;
        }
        else {
            return [];
        }
    }

    const onChange = (event:any) => {
        onSave(event.target.value);
        setDataChange(true)
    }

    return (
        <Box sx={mobilePhoneStyle}>
            {selectableFacilities().length > 0 && (
            <>
            <Typography variant="body1">{t("Välj_Anläggning_Ledtext")}</Typography>
            <Grid item xs="auto" sx={{pt:2}}>
                <FormControl>
                    <InputLabel id="drop-down-label">{t("Anläggning")}</InputLabel>
                    <Select
                        disabled={selectableFacilities().length < 2}
                        labelId="drop-down-label"
                        size="small"
                        name={t("Välj_Anläggning")}
                        label={t("Anläggning")}
                        value={data.id}
                        onChange={onChange}        
                    >
                    {selectableFacilities().map((item) =>
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                    )}
                    </Select>
                </FormControl>
            </Grid>
            </>
            )}
            {selectableFacilities().length < 1 &&
                <Typography variant="body1">{t("Inga_anläggningar")}</Typography>
            }
        </Box>
    )
}