import { Link, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import {Link as RouterLink} from "react-router-dom";
import ListItem from '@mui/material/ListItem';
import React from 'react';
import {useTranslation} from "react-i18next";

interface ListItemProps {
    open: boolean,
    item: {
        text: string,
        icon: React.JSX.Element,
        url: string,
        action: () => void
    }
}

const CustomMobileDrawerListItem: React.FC<ListItemProps> = ({ open, item }) => {
    const { t } = useTranslation();

    return (
        <>
            <ListItem key={t(item.text)} disablePadding sx={{display: "block"}}>
                <Link
                    sx={{textDecoration: "none"}}
                    component={RouterLink}
                    to={item.url}>
                    <ListItemButton
                        onClick={item.action}
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                        }}>
                        <Tooltip title={t(item.text)}>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}>
                                {item.icon}
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={t(item.text)}
                            sx={{
                                opacity: open ? 1 : 0,
                            }}
                            primaryTypographyProps={{
                            }}
                        />
                    </ListItemButton>
                </Link>
            </ListItem>    
        </>
    );
};

export default CustomMobileDrawerListItem;
