import {ApiResponse, ApiResponseSingle, extractEmbeddedResource, fetchData, fetchUnauthenticated } from "./apiUtils";
import { User } from "../types/User";
import { Group } from "../types/Group";
import { GridSortModel } from "@mui/x-data-grid";

export const fetchUser = async (id:string): Promise<ApiResponseSingle<User>> => {
    const apiUrl = `/api/users/${id}`;
    const options = {
        method: "GET"
    };
    const response = fetchData<User>(apiUrl, options);
    return response as Promise<ApiResponseSingle<User>>;
}

export const existsByUsername = async (username: string) : Promise<ApiResponseSingle<Boolean>> => {
    const apiUrl = `/api/users/search/existsByUsername?username=${username}`;
    const options = {
        method: "GET"
    };
    const response = fetchData<Boolean>(apiUrl, options);
    return response as Promise<ApiResponseSingle<Boolean>>;
}

export const findUserByCustomerId = async (
    customerid: string,
    page: number,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'fullname', sort: 'asc'}]
) : Promise<ApiResponse<User>> => {
    const params = [
        `customerid=${customerid}`,
        `page=${page}`,
        `size=${rowsPerPage}`,
        sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
    ];
    const apiUrl = `/api/users/search/findByCustomerId?${params.join('&')}`;
    const options = {
        method: "GET"
    };
    const response = fetchData<User[]> (
        apiUrl,
        options,
        (responseData) => extractEmbeddedResource<User>(responseData, `users`)
    );
    return response as Promise<ApiResponse<User>>;
}

export const findUserByCustomerIdAndUsernameLikeOrFullnameLike = async (
    customerid: string,
    search: string,
    page: number,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'fullname', sort: 'asc'}]
) : Promise<ApiResponse<User>> => {
    const params = [
        `customerid=${customerid}`,
        `search=%25${search}%25`,
        `page=${page}`,
        `size=${rowsPerPage}`,
        sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
    ];
    const apiUrl = `/api/users/search/findByCustomerIdAndUserNameLikeOrFullNameLike?${params.join('&')}`;
    const options = {
        method: "GET"
    };
    const response = fetchData<User[]> (
        apiUrl,
        options,
        (responseData) => extractEmbeddedResource<User>(responseData, `users`)
    );
    return response as Promise<ApiResponse<User>>;
}

export const findUserByCustomerIdAndActiveIsAndUsernameLikeOrFullnameLike = async (
    customerid: string,
    active: boolean | null,
    search: string,
    page: number,
    rowsPerPage: number = 100,
    sortModel: GridSortModel = [{field: 'fullname', sort: 'asc'}]
) : Promise<ApiResponse<User>> => {
    const params = [
        `customerid=${customerid}`,
        `active=${active}`,
        `search=%25${search}%25`,
        `page=${page}`,
        `size=${rowsPerPage}`,
        sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
    ];
    const apiUrl = `/api/users/search/findByCustomerIdAndAcitveIsAndUsernameLikeOrFullnameLike?${params.join('&')}`;
    const options = {
        method: "GET"
    };
    const response = fetchData<User[]> (
        apiUrl,
        options,
        (responseData) => extractEmbeddedResource<User>(responseData, `users`)
    );
    return response as Promise<ApiResponse<User>>;
}

export const resetUserPassword = async (username: string): Promise<ApiResponseSingle<string>> => {
    const apiUrl = `/api/reset`;
    const options = {
        method: "POST",
        body: username
    }
    const response = fetchUnauthenticated(apiUrl, options);
    return response as Promise<ApiResponseSingle<string>>;
}

export const updateUser = async (id: string, updateData: {fullname:string, active?:boolean, expires?: string | null, email?: string | null, phone?: string | null}): Promise<ApiResponseSingle<User>> => {
    const apiUrl = `/api/users/${id}`;
    const options = {
        method: "PATCH",
        body: JSON.stringify(updateData)
    };
    const response = fetchData<User>(apiUrl, options);
    return response as Promise<ApiResponseSingle<User>>;
}

export const createUser = async (user: User): Promise<ApiResponseSingle<User>> => {
    const apiUrl = `/api/users`;
    const options = {
        method: "POST",
        body: JSON.stringify(user)
    };
    const response = fetchData<User>(apiUrl, options);
    return response as Promise<ApiResponseSingle<User>>;
}

export const fetchLoggedInUser = async(): Promise<ApiResponseSingle<User>> => {
    const apiUrl = `/api/users/search/getLoggedInUser`;

    const options = {
        method: "GET"
    };
    const response = fetchData<User>(apiUrl, options);
    return response as Promise<ApiResponseSingle<User>>;
}

export const setUserRolesAndGroupsAndFacilities = async(id: string, roles: number[], groups: number[], facilities: number[]): Promise<ApiResponseSingle<User>> => {
    const params = [
        `id=${id}`,
        `roleIds=${roles.join(',')}`,
        `groupIds=${groups.join(', ')}`,
        `facilityIds=${facilities.join(', ')}`
    ];
    const apiUrl = `/api/users/search/setUserRolesAndGroupsAndFacilities?${params.join('&')}`;
    const options ={
        method: "GET"
    };
    const response = fetchData<User>(apiUrl, options);
    return response as Promise<ApiResponseSingle<User>>;
}

export const getPermanentGroups = async (): Promise<ApiResponse<Group>> => {
    const apiUrl = `/api/users/search/getPermanentGroups`;
    const options = {
        method: "GET"
    };
    
    const response = fetchData<Group[]>(
        apiUrl, 
        options,
        (responseData) => extractEmbeddedResource<Group>(responseData, `groups`)
    );
    return response as Promise<ApiResponse<Group>>;
}

