
export enum UserLoginType {
    EMAIL,
    USERNAME
}

export const customersWithUserNameLoginType = ['1', '2', '3'];

export interface User {
    id: string,
    username: string,
    fullname: string,
    phone: string,
    email: string,
    active: boolean,
    expires: string,
    customerIds: number[],
    userRoles: {id:number, name:string}[],
    userGroups: {id:number, name:string}[],
    userFacilities: {id: number, name:string}[]
}

export const rowToUser = (data:any) => {
    const {
        id,
        username,
        fullname,
        email,
        phone,
        active,
        expires,
        customerIds,
        userRoles,
        userGroups,
        userFacilities
    } = data;
    return {
        id,
        username,
        fullname,
        email,
        phone,
        active,
        expires,
        customerIds,
        userRoles,
        userGroups,
        userFacilities
    } as User;
}

