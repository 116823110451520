enum MessageType {
    NEW_VISIT = "NEW_VISIT",
    SETTINGS_DATA = "SETTINGS_DATA",
    CURRENT_FACILITY = "CURRENT_FACILITY"
}

enum MobileAction {
    SOUND = "sound",
    NOTIFICATION = "notification",
    VIBRATE = "vibrate",
}

interface MobileNotification {
    type: MessageType;
    action?: MobileAction[];
    message?: string;
    data?: any;
    facilityId?: number
}

export class FacilitySelection implements MobileNotification {
    type: MessageType.CURRENT_FACILITY;
    action: MobileAction[];
    message?: string;
    data?: any;
    facilityId?: number | undefined;

    constructor(message?: string, data?: any, facilityId?: number) {
        this.type = MessageType.CURRENT_FACILITY;
        this.action = [];
        this.message = message;
        this.data = data;
        this.facilityId = facilityId;
    }
}

export class NewVisitNotification implements MobileNotification {
    type: MessageType.NEW_VISIT;
    action: MobileAction[];
    message?: string;
    data?: any;
    facilityId?: number | undefined;

    constructor(message?: string, data?: any, actions?: MobileAction[]) {
        this.type = MessageType.NEW_VISIT;
        this.action = actions? actions : [MobileAction.SOUND, MobileAction.VIBRATE];
        this.message = message;
        this.data = data;
        this.facilityId = undefined;
    }

}

export class SettingsData implements MobileNotification {
    type: MessageType.SETTINGS_DATA;
    action: MobileAction[];
    message?: string;
    data?: any;
    facilityId?: number | undefined;

    constructor(message?: string, data?: any) {
        this.type = MessageType.SETTINGS_DATA;
        this.action = [];
        this.message = message;
        this.data = data;
        this.facilityId = undefined;
    }
}
