import {AuthenticatedPage} from "./AuthenticatedPage";
import {Skeleton, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import {BillableVisit} from "../types/BillableVisit";
import {VehicleListItem} from "../components/RealTimeView/VehicleListItem";
import {
    fetchAdditionalBillableVisits,
    fetchInitialBillableVisits
} from "../api/BillableVisitsAPI";
import {motion} from "framer-motion";
import {useAppBarProps} from "../components/Header/HeaderContainer";
import {PostMessageHelper} from "../helpers/PostMessageHelper";
import {ErrorResponse} from "../api/apiUtils";
import {loadDefaultData} from "../components/Settings/Settings";
import {FacilitySelection, NewVisitNotification} from "../types/MobileNotifications";
import { useIsAuthenticated } from "@azure/msal-react";
import { fetchLoggedInUser } from "../api/UserAPI";
import { allAuthorizedUserRoles, flAdminRole } from "../types/CurrentUserRoles";
import { isEmptyArray } from "../helpers/IsEmptyHelpers";
import { useCurrentUser } from "../contexts/CurrentUserContext";
import { useCurrentCustomer } from "../contexts/CurrentCustomerContext";
import { fetchCustomer } from "../api/CustomerAPI";
import { CurrentCustomerProducts } from "../types/CurrentCustomerProducts ";
import { BillableVisitType } from "../types/enums/BillableVisitType";
import { UserFacility } from "../types/CurrentUserFacilities";
import { useNavigate } from "react-router-dom";
import { PullDownContent, PullToRefresh, RefreshContent, ReleaseContent } from "react-js-pull-to-refresh";

const mobilePhoneStyle = {
    borderRadius: '12px',
    padding: "10px",
    backgroundColor: '#f0f0f0',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    margin: 'auto',
    marginTop: '30px',
    overflow: "auto",
    '&::-webkit-scrollbar': {display: 'none'},
    overflowY:"hidden",
    minHeight:'90%'
};

const POLLING_INTERVAL = 5000; // Polling interval in milliseconds (e.g., 5000 ms = 5 seconds)

export function RealTimeView() {
    const [billableVisits, setBillableVisits] = useState<BillableVisit[]>([]);
    const [filteredVisits, setFilteredVisits] = useState<BillableVisit[]>([]);
    const [errors, setErrors] = useState<ErrorResponse[]>([]);
    const [previousVisitId, setPreviousVisitId] = useState("");
    const [loading, setLoading] = useState(false);
    const [earliestVisit, setEarliestVisit] = useState<BillableVisit>();
    const {searchQuery, filterPreRegistered} = useAppBarProps();
    const [openId, setOpenId] = useState('');
    const [settingsData, setSettingsData] = useState<any[]>([]);
    const {currentUser, setCurrentUser} = useCurrentUser();
    const {currentCustomer, setCurrentCustomer} = useCurrentCustomer();
    const isAuthenticated = useIsAuthenticated();
    const [currentFacility, setCurrentFacility] = useState<UserFacility|null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if(isAuthenticated && (!currentUser || !currentUser.isAuthorized)){
            getUser();
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if(currentUser.isAuthorized && !currentUser.isAdmin && (!currentCustomer || !currentCustomer.id || currentCustomer.id < 0)) {
            getCustomer();
        }
        else if(currentUser.isAdmin && (!currentCustomer || !currentCustomer.id || currentCustomer.id < 0)){
            navigate("/customers")
        }
    }, [currentUser]);

    useEffect(() => {
        if(currentUser.isAuthorized) {
            if(!currentFacility) {
                loadCurrentFacility();
            }
            else {
                fetchInitialData();
            }
        }
    }, [currentCustomer, currentFacility]);

    useEffect(() => {
        if (billableVisits.length > 0) {
            setEarliestVisit(billableVisits[0]);
        }
    }, [billableVisits]);

    useEffect(() => {
            const intervalId = setInterval(fetchPolledData, POLLING_INTERVAL);
            return () => clearInterval(intervalId);
    }, [previousVisitId, currentFacility]);

    useEffect(() => {
        const filteredResults = billableVisits.filter((visit) =>
            visit.vehicleData.regnumberClear.includes(searchQuery.toUpperCase()) &&
            (!filterPreRegistered || visit.type === BillableVisitType.PRE_REGISTERED) && shouldShowVisitType(visit.type)
        );
        setFilteredVisits(filteredResults);
    }, [searchQuery, filterPreRegistered, billableVisits]);

    const shouldShowVisitType = (type: string) => {
        if(type === BillableVisitType.DUPLICATE){
            return false;
        }
        else if(type === BillableVisitType.EXEMPT) {
            return true;
        }
        else if(type === BillableVisitType.PRE_REGISTERED) {
            return true;
        }
        else if(type.startsWith("FREE")) {
            return settingsData.some(setting => setting.name.startsWith("FREE") && setting.show);
        }
        else {
            return settingsData.find(setting => setting.name === type && setting.show);
        }
    };

    const getUser = async () => {
        try {
          setLoading(true);
          const {data, errors} = await fetchLoggedInUser();
          if(isEmptyArray(errors)){
              setCurrentUser({
                  name: data.username,
                  fullName: data.fullname,
                  customerIds: data.customerIds,
                  groups: data.userGroups, 
                  roles: data.userRoles,
                  isAuthorized: data.userRoles.some(r => allAuthorizedUserRoles.map(role => role.id).includes(r.id)),
                  isAdmin: data.userRoles.map(role => role.id).includes(flAdminRole.id),
                  facilities: data.userFacilities
              })
          }
          else {
            setCurrentUser({isAuthorized: false, isAdmin: false, roles: [], groups: []});
          }
            setErrors(errors);
        } finally {
        setLoading(false);
        }
    };

    const getCustomer = async () => {
        setLoading(true);
        if(currentUser && currentUser.isAuthorized && !currentUser.isAdmin && currentUser.customerIds?.length === 1){
          const {data} = await fetchCustomer(currentUser.customerIds[0].toString());
          setCurrentCustomer({
            id: currentUser.customerIds[0],
            name: data.name,
            facilities: data.facilityNames,
            services: data.services,
            productNames: data.productNames,
            customerGroups: data.customerGroups
          });
        }
        setLoading(false);
    };

    const fetchInitialData = async () => {
        if(currentFacility) {
            const {data, errors} = await fetchInitialBillableVisits(20, currentCustomer.id, currentFacility.id);
            const reversedData = [...data].reverse();
            setBillableVisits(reversedData);
            setFilteredVisits(reversedData);
            setErrors(errors);
        }
        loadLocalStorage();
    };

    const hasMarkedItemService = () => {
        return currentCustomer.productNames? currentCustomer.productNames.includes(CurrentCustomerProducts.MARKED_SERVICES) : false;
    }

    const loadCurrentFacility = () => {
        let data = window.localStorage.getItem("CURRENT_FACILITY");
        if (data && currentCustomer && currentCustomer.id && currentCustomer.id > 0) {
            let obj;
            try {
                obj = JSON.parse(data);
            }
            catch {
                obj = {id: 0, name: ""};
            }
            if(currentUser && currentUser.isAdmin && currentCustomer && currentCustomer.facilities && currentCustomer.facilities.some(f => f.id === obj.id)) {
                setCurrentFacility(obj);
                saveCurrentFacility(obj);
            }
            else if (currentUser && currentUser.facilities && currentUser.facilities.some(f => f.id === obj.id) && 
                currentCustomer && currentCustomer.facilities && currentCustomer.facilities.some(f => f.id === obj.id)){
                setCurrentFacility(obj);
                saveCurrentFacility(obj);
            }
            else if(currentUser && currentUser.facilities && currentUser.facilities.length > 0){
                setCurrentFacility(currentUser.facilities[0])
                saveCurrentFacility(currentUser.facilities[0])
            }
            else {
                setCurrentFacility(currentCustomer.facilities[0])
                saveCurrentFacility(currentCustomer.facilities[0])
            }
        }
        else if(currentUser && currentUser.facilities && currentUser.facilities.length > 0){
            setCurrentFacility(currentUser.facilities[0])
            saveCurrentFacility(currentUser.facilities[0])
        }
        else if(currentCustomer && currentCustomer.facilities && currentCustomer.facilities.length > 0) {
            setCurrentFacility(currentCustomer.facilities[0]);
            saveCurrentFacility(currentCustomer.facilities[0]);
        }
        else if(currentUser.isAdmin){
            navigate("/customers")
        }
    }

    const saveCurrentFacility = (facility: UserFacility) => {
        let serializedData = JSON.stringify(facility);
        window.localStorage.setItem("CURRENT_FACILITY", serializedData);
        const settingsData = new FacilitySelection("", "", facility.id)
        PostMessageHelper(JSON.stringify(settingsData));
        
    }

    const loadLocalStorage = () => {
        let data = window.localStorage.getItem("SETTINGS_DATA");
        let obj: any[];
        if(data){
            obj =  JSON.parse(data)
        } else {
            obj = loadDefaultData();
        }
        obj = obj.filter(item => item.name !== "EXTRA_INFO");
        obj = obj.filter(item => (item.name !== "RED_LIST") || hasMarkedItemService());
        setSettingsData(obj)
    }

    const fetchPolledData = async () => {
        if(currentFacility){
            const {data, errors} = await fetchInitialBillableVisits(1, currentCustomer.id, currentFacility.id);
            const visitId = data[0]?.id || "";
            if (previousVisitId !== "" && visitId !== previousVisitId) {
                setBillableVisits((prevArray) => [...prevArray, ...data]);
                if (data[0]?.vehicleData?.regnumberClear) {
                    const notification = new NewVisitNotification("", data[0].vehicleData.regnumberClear);
                    PostMessageHelper(JSON.stringify(notification));
                }
            }
            setPreviousVisitId(visitId);
            setErrors(errors);
        }
    };

    const fetchAdditionalData = async () => {
        setLoading(true);
        if (earliestVisit && currentFacility) {
            const {data, errors} = await fetchAdditionalBillableVisits(21, currentCustomer.id, currentFacility.id, earliestVisit.vehicleData.sensorTime);
            data.shift();
            const reversedData = [...data].reverse();
            setBillableVisits((prevData) => [...reversedData, ...prevData]);
            setErrors(errors);
        }
        setLoading(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop;
            if (windowHeight + Math.round(scrollTop) >= documentHeight) {
                fetchAdditionalData();
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [earliestVisit]);


    const toggleDetails = (inputId: React.SetStateAction<string>) => {
        if(openId !== inputId) {
            setOpenId(inputId);
        }
    };

    return (
        <AuthenticatedPage>
            <Box sx={mobilePhoneStyle}>
            <PullToRefresh
                pullDownContent={<PullDownContent />}
                releaseContent={<ReleaseContent />}
                refreshContent={<RefreshContent />}
                onRefresh={fetchInitialData}
                startInvisible={true} 
                pullDownThreshold={75}
                triggerHeight={100}
            >
                <Stack
                    spacing={1}
                    flexDirection={"column-reverse"}
                    sx={{    overflow: "auto",
                        '&::-webkit-scrollbar': {display: 'none'},'& > :first-of-type': {marginTop: '8px', minHeight:'90%'}}}>
                    {filteredVisits.map((billableVisit) => (
                            <motion.div
                                key={billableVisit.clientRef}
                                layout
                                className="box"
                                initial={{opacity: 0, scale: 0.5}}
                                animate={{opacity: 1, scale: 1}}
                                transition={{
                                    duration: 0.5,
                                    ease: [0, 0.71, 0.2, 1.01],
                                    scale: {
                                        type: "spring",
                                        damping: 10,
                                        stiffness: 100,
                                        restDelta: 0.001
                                    }
                                }}>
                                <VehicleListItem open={openId === billableVisit.clientRef} onOpen={toggleDetails} billableVisit={billableVisit} settings={settingsData}/>
                            </motion.div>
                    ))}
                </Stack>
                {errors.length > 0 && (
                    <div>
                        <h2>Error:</h2>
                        <ul>
                            {errors.map((error, index) => (
                                <li key={index}>{error.message}</li>
                            ))}
                        </ul>
                    </div>
                )}

                <div>
                    <Skeleton hidden={loading} animation="wave"/>
                </div>
                </PullToRefresh>
                </Box>
        </AuthenticatedPage>
    )
}