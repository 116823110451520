import { darken, emphasize, lighten } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { canBeHandledAfterVisit, inactiveStatuses } from "../../../types/enums/PreRegistrationType";

export const getColor = (color: string, mode: string, coefficient: number) => 
    mode === 'dark'? darken(color, coefficient) : lighten(color, coefficient);

export const boxStyle = {
    maxWidth: {xs: '100%', sm: '95%'},
    borderRadius: (theme) => theme.spacing(1),
    boxShadow: 4,
    '& .MuiDataGrid-columnHeaders': {
        backgroundColor: (theme) => emphasize(theme.palette.background.default)
    },
    '& .MuiDataGrid-iconSeparator': {
        color: (theme) => theme.palette.primary.light,
    },
    '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
        display: "none"
    },
    '& .custom-nonPayable-item': {
        '&.MuiDataGrid-row': {
            backgroundColor: (theme) => getColor(theme.palette.grey[500], theme.palette.mode, 0.6),
            color: (theme) => getColor(theme.palette.common.black, theme.palette.mode, 0.5),
        },
        '&:hover': {
            '&.MuiDataGrid-row:hover, &.MuiDataGrid-row.Mui-hovered': { 
                backgroundColor: (theme) => getColor(theme.palette.secondary.main, theme.palette.mode, 0.5),
                color: (theme) => getColor(theme.palette.common.black, theme.palette.mode, 0)
            }
        }
    },
    '& .custom-payable-item': {
            '&.MuiDataGrid-row': {
        backgroundColor: (theme) => getColor(theme.palette.background.default, theme.palette.mode, 1),
        },
        '&:hover': {
            '&.MuiDataGrid-row:hover, &.MuiDataGrid-row.Mui-hovered': {
                backgroundColor: (theme) => getColor(theme.palette.secondary.main, theme.palette.mode, 0.5),
                color: (theme) => getColor(theme.palette.common.black, theme.palette.mode, 0)
            }
        }
    },
    '& .custom-inactive-item': {
        '&.MuiDataGrid-row': {
            backgroundColor: (theme) => getColor(theme.palette.grey[500], theme.palette.mode, 0.6),
            color: (theme) => getColor(theme.palette.common.black, theme.palette.mode, 0.5),
        },
        '&:hover': {
            '&.MuiDataGrid-row:hover, &.MuiDataGrid-row.Mui-hovered': {
                backgroundColor: (theme) => getColor(theme.palette.secondary.main, theme.palette.mode, 0.5),
                color: (theme) => getColor(theme.palette.common.black, theme.palette.mode, 0)
            }
        },
    },
    '& .custom-active-item': {
        '&.MuiDataGrid-row': {
            backgroundColor: (theme) => getColor(theme.palette.background.default, theme.palette.mode, 1),
        },
        '&:hover': { 
            '&.MuiDataGrid-row:hover, &.MuiDataGrid-row.Mui-hovered': {
                backgroundColor: (theme) => getColor(theme.palette.secondary.main, theme.palette.mode, 0.5),
                color: (theme) => getColor(theme.palette.common.black, theme.palette.mode, 0)
            }
        }
    },
    '& .custom-visited-item': {
        '&.MuiDataGrid-row': {
            backgroundColor: (theme) => getColor(theme.palette.grey[500], theme.palette.mode, 0.6),
            color: (theme) => getColor(theme.palette.common.black, theme.palette.mode, 0.5),
        },
        '&:hover': {
            '&.MuiDataGrid-row.Mui-hovered': {
                backgroundColor: (theme) => getColor(theme.palette.secondary.main, theme.palette.mode, 0.5),
                color: (theme) => getColor(theme.palette.common.black, theme.palette.mode, 0)
            }
        },
    },
}

export const isActiveDateTime = (row) => {
    const now = dayjs().endOf('minute');
    const validUntil = row.validUntil;
    return dayjs(validUntil).isAfter(now);
};

export const isActiveDate = (row) => {
    const today = dayjs().endOf("day");
    const validFrom = row.validFrom;
    const validUntil = row.validUntil;
    if (dayjs(validFrom).isAfter(today)) {
        return false;
    }
    else if(validUntil !== null && dayjs(validUntil).isBefore(today)) {
        return false;
    }
    else {
        return true;
    }

};

export const showAsActive = (row) => {
    const today = dayjs().startOf("day");
    const validFrom = row.validFrom;
    const validUntil = row.validUntil;
    if (dayjs(validFrom).isAfter(today)) {
        return false;
    }
    else if(validUntil !== null && dayjs(validUntil).isBefore(today)) {
        return false;
    }
    else {
        return true;
    }

};

export const getDateVisitRowClassName = (params: GridRowParams): string => {
    return showAsActive(params.row) ? `custom-active-item` : `custom-inactive-item`;
}

export const getDateTimeVisitRowClassName = (params: GridRowParams): string => {
    return isActiveDateTime(params.row) ? `custom-active-item` : `custom-inactive-item`;
}

export const hasVisited = (row: any) => {
    return row.visitTime !== null && row.visitTime > 0 && dayjs(row.visitTime).isValid();
};


export const getPreRegisterRowClassName = (params: GridRowParams): string => {
    const status = params.row.status;
    const validUntil = params.row.validUntil;
    if(hasVisited(params.row)) {
        return (canBeHandledAfterVisit.includes(status) && dayjs(validUntil).isSame(dayjs(), 'month'))  ? `custom-visited-item` : `custom-inactive-item`;
    }
    return (inactiveStatuses.includes(status) || !isActiveDateTime(params.row)) ? `custom-inactive-item` : `custom-active-item`;
}
