import {AuthenticatedPage} from "./AuthenticatedPage";
import React, {useEffect,  useState} from "react";
import {SettingsComponent} from "../components/Settings/SettingsComponent";
import {loadDefaultData} from "../components/Settings/Settings";
import {PostMessageHelper} from "../helpers/PostMessageHelper";
import {SettingsData} from "../types/MobileNotifications";
import { useCurrentCustomer } from "../contexts/CurrentCustomerContext";
import { CurrentCustomerProducts } from "../types/CurrentCustomerProducts ";

export function SettingsView() {
    const [initialData, setData] = useState<any[]>([]);
    const {currentCustomer} = useCurrentCustomer();

    const hasMarkedItemService = () => {
        return currentCustomer.productNames? currentCustomer.productNames.includes(CurrentCustomerProducts.MARKED_SERVICES) : false;
    }

    useEffect(() => {
        loadLocalStorage();
    }, []);

    const loadLocalStorage = () => {
        let data = window.localStorage.getItem("SETTINGS_DATA");
        let obj: any[];
        if(data){
            obj =  JSON.parse(data)
        } else {
            obj = loadDefaultData();
        }
        obj = obj.filter(item => item.name !== "EXTRA_INFO");
        obj = obj.filter(item => (item.name !== "RED_LIST") || hasMarkedItemService());
        if(hasMarkedItemService() && !obj.some(item => item.name === "RED_LIST")) {
            obj.push({name: "RED_LIST", notify: true})
        }
        setData(obj)
    }

    const saveLocalStorage = (changeData:any) => {
        let serializedData = JSON.stringify(changeData);
        window.localStorage.setItem("SETTINGS_DATA", serializedData);
        const settingsData = new SettingsData("", changeData)
        PostMessageHelper(JSON.stringify(settingsData));
    }


    return (
        <AuthenticatedPage>
            {initialData && initialData.length >0 && (
            <SettingsComponent onSave={saveLocalStorage} data={initialData}></SettingsComponent>
            )}
        </AuthenticatedPage>
    )
}